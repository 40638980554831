import { Col, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import React, { useState } from 'react';
import { backgroundPrimary, bgWhite } from '../styles/background.css';

import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import { PrimaryFont } from '../styles/font.css';
import SpecBlackbirdFW from './SpecBlackbirdFW';
import SpecBlackbirdVTOL from './SpecBlackbirdVTOL';
import { spesifikasiFrame } from '../styles/spesifikasi.css';

export default function KDSpesifikasi() {
  const rowClasses = KDMakeClasses([backgroundPrimary, 'g-0', 'pt-5', 'pb-5']);
  const innerRowClasses = KDMakeClasses([bgWhite, 'g-0', spesifikasiFrame]);
  const spesifikasiTitle = KDMakeClasses([
    PrimaryFont,
    'text-center',
    'pt-5',
    'pb-3',
  ]);
  const [key, setKey] = useState('VTOL');
  return (
    <div id="spesifikasi">
      <KDRow className={rowClasses}>
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
        <Col md={10} sm={10} lg={10} xl={10} xs={10}>
          <Row className={innerRowClasses}>
            <Col md={12} sm={12} lg={12} xl={12} xs={12}>
              <h2 className={spesifikasiTitle}>Spesifikasi LUAV</h2>
              <Nav className="nav-tabs">
                <Nav.Item onClick={() => setKey('VTOL')}>
                  <Nav.Link
                    active={key == 'VTOL' ? true : false}
                    onClick={() => setKey('VTOL')}
                  >
                    <p className={PrimaryFont}>LUAV Blackbird VTOL</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => setKey('FW')}>
                  <Nav.Link
                    active={key == 'FW' ? true : false}
                    onClick={() => setKey('FW')}
                  >
                    <p className={PrimaryFont}>LUAV Blackbird Fixed Wing</p>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {key == 'VTOL' && <SpecBlackbirdVTOL />}
              {key == 'FW' && <SpecBlackbirdFW />}
            </Col>
          </Row>
        </Col>
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      </KDRow>
    </div>
  );
}
