import * as React from 'react';

import { graphql, type PageProps } from 'gatsby';

import KDContainer from '../components/Container';
import { KDHead } from '../components/Head';
import KDNav from '../components/Navbar';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import KDFooter from '../components/Footer';
import Jumbotron from '../components/Jumbotron';
import KDFeature from '../components/Feature';
import KDVideo from '../components/VideoSection';
import KDSpesifikasi from '../components/Spesifikasi';
import KDPaketInfo from '../components/PaketInfo';
import KDLastInfo from '../components/LastInfo';
import KDCTA from '../components/CTA';

const IndexPage = ({ data }: PageProps<Queries.LuavIndexQueryQuery>) => {
  const logoSmall =
    data.logoSmall.edges[0].node.childImageSharp.gatsbyImageData;

  const jumbotronImage =
    data.jumbotronImage.edges[0].node.childImageSharp.gatsbyImageData;

  const jumbotronSubImage =
    data.jumbotronSubImage.edges[0].node.childImageSharp.gatsbyImageData;

  const iconFeatureAuto =
    data.ifAuto.edges[0].node.childImageSharp.gatsbyImageData;
  const iconFeatureCamera =
    data.ifCamera.edges[0].node.childImageSharp.gatsbyImageData;
  const iconFeatureCuaca =
    data.ifCuaca.edges[0].node.childImageSharp.gatsbyImageData;
  const iconFeatureJangkauan =
    data.ifJangkauan.edges[0].node.childImageSharp.gatsbyImageData;
  const iconFeatureJarak =
    data.ifJarak.edges[0].node.childImageSharp.gatsbyImageData;
  const iconFeaturePnp =
    data.ifPnp.edges[0].node.childImageSharp.gatsbyImageData;
  const iconFeaturePrep =
    data.ifPrep.edges[0].node.childImageSharp.gatsbyImageData;
  const iconFeatureVideo =
    data.ifVideo.edges[0].node.childImageSharp.gatsbyImageData;

  const infoImage =
    data.infoImage.edges[0].node.childImageSharp.gatsbyImageData;

  const infoGaransi =
    data.liGaransi.edges[0].node.childImageSharp.gatsbyImageData;
  const infoSupport =
    data.liSupport.edges[0].node.childImageSharp.gatsbyImageData;
  const infoCourse =
    data.liCourse.edges[0].node.childImageSharp.gatsbyImageData;

  const ctaImage = data.ctaImage.edges[0].node.childImageSharp.gatsbyImageData;

  const logoMedium =
    data.logoMedium.edges[0].node.childImageSharp.gatsbyImageData;

  const fiturUnggulanData = [
    {
      title: 'Cakupan Area hingga 1200 Ha',
      content:
        'Sensor 61 MP, terbang 110 menit, hasilkan peta 1200 Ha dengan detail 3/CM per pixel. Efisiensi yang tiada tara.',
      icon: iconFeatureJangkauan,
    },
    {
      title: 'Detail terbaik: Kamera 61 megapixel',
      content:
        'Dapatkan hasil terbaik dengan kamera 61 Megapixel kami. Opsi ini memastikan detail terlengkap dan cakupan terluas.',
      icon: iconFeatureCamera,
    },
    {
      title: 'Jarak sinyal hingga 50 kilometer',
      content:
        'Nikmati sinyal kendali yang handal dengan jangkauan hingga 50KM dan redundansi dari jaringan 4G/LTE.',
      icon: iconFeatureJarak,
    },
    {
      title: 'Video streaming secara langsung',
      content:
        'Tersedia video langsung di kontroler. Anda dapat menilai kualitas data pemetaan Anda selama penerbangan.',
      icon: iconFeatureVideo,
    },
    {
      title: 'Terbang dengan aman walau hujan',
      content:
        'Teknologi cerdas memberikan kemampuan untuk terbang dengan aman saat hujan dengan intensitas normal.',
      icon: iconFeatureCuaca,
    },
    {
      title: 'Serbaguna, muatan mudah untuk ditukar',
      content:
        'Dudukan kamera dan muatan yang dapat diganti menghilangkan kebutuhan untuk membeli banyak UAV.',
      icon: iconFeaturePnp,
    },
    {
      title: 'Siap terbang hanya dalam 2 menit',
      content:
        'Efisiensi waktu dan tenaga: Dapat langsung digunakan kapanpun Anda butuhkan, tanpa persiapan yang lama.',
      icon: iconFeaturePrep,
    },
    {
      title: 'Sepenuhnya otomatis',
      content:
        'Otomatis dari lepas landas hingga pendaratan, tanpa perlu intervensi manusia, bahkan di luar jangkauan komunikasi.',
      icon: iconFeatureAuto,
    },
  ];

  const lastInfoData = [
    {
      icon: infoGaransi,
      title: 'Garansi',
    },
    {
      icon: infoSupport,
      title: 'Support',
    },
    {
      icon: infoCourse,
      title: 'E-Course',
    },
  ];
  return (
    <main>
      <KDContainer>
        <KDNav logoImage={logoSmall} />
        <Jumbotron
          jumboImage={jumbotronImage}
          jumboSubImage={jumbotronSubImage}
        ></Jumbotron>
        <KDFeature featureData={fiturUnggulanData}></KDFeature>
        <KDVideo />
        <KDSpesifikasi />
        <KDPaketInfo infoImage={infoImage} />
        <KDLastInfo lastInfoData={lastInfoData} />
        <KDCTA ctaImage={ctaImage} />
        <KDFooter footerImage={logoMedium} />
      </KDContainer>
      <FloatingWhatsApp
        accountName="LUAV Aerial Technology"
        phoneNumber="+6282334639477"
        statusMessage="Online"
        chatMessage="Halo, Ada yang bisa kami bantu?"
        avatar={
          data.logoSky.edges[0].node.childImageSharp.gatsbyImageData.images
            .fallback.src
        }
      />
    </main>
  );
};

export default IndexPage;
export const LuavIndexQuery = graphql`
  query LuavIndexQuery {
    logoSmall: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "logo-small" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    jumbotronImage: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "assets-flying-luav" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    jumbotronSubImage: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "assets-skywalker2" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    logoSky: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "assets-logo-skywalker" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    ifAuto: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "icon-feature-auto" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    ifCamera: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "icon-feature-camera" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    ifCuaca: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "icon-feature-cuaca" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    ifJangkauan: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "icon-feature-jangkauan" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    ifJarak: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "icon-feature-jarak" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    ifPnp: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "icon-feature-pnp" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    ifPrep: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "icon-feature-prep" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    ifVideo: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "icon-feature-video" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    infoImage: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "assets-skywalker0" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    liGaransi: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "icon-garansi" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    liSupport: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "icon-support" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    liCourse: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "icon-course" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    ctaImage: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "assets-logo-skywalker" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    logoMedium: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "logo-medium" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export const Head = () => <KDHead />;
