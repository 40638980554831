import { Col, Row } from 'react-bootstrap';
import { FontSizeXSmall, PrimaryFont } from '../styles/font.css';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {
  jumbotronMobileText,
  jumbotronSecondaryText,
  jumbotronSecondaryTextMobile,
  jumbotronText,
} from '../styles/jumbotron.css';

import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import React from 'react';
import { backgroundPrimary } from '../styles/background.css';
import { textColorWhite } from '../styles/text.css';

type JumbotronProps = {
  jumboImage: IGatsbyImageData;
  jumboSubImage: IGatsbyImageData;
};

export default function KDJumbotron({
  jumboImage,
  jumboSubImage,
}: JumbotronProps) {
  const jumbotronTextClasses = KDMakeClasses([
    'w-100',
    'position-absolute',
    'g-0',
    jumbotronText,
    PrimaryFont,
    'd-none',
    'd-sm-block',
  ]);
  const jumbotronTitleClasses = KDMakeClasses([textColorWhite, 'mx-5', 'pl-5']);
  const jumbotronSubTitleClasses = KDMakeClasses([
    textColorWhite,
    'mx-5',
    'my-4',
    'pl-5',
    PrimaryFont,
  ]);
  const jumbotronButtonClasses = KDMakeClasses([
    'btn',
    'btn-light',
    'rounded-pill',
    'mx-5',
    'pl-5',
    PrimaryFont,
  ]);

  // MOBILE
  const jumbotronTextMobileClasses = KDMakeClasses([
    'w-100',
    'position-absolute',
    'g-0',
    jumbotronMobileText,
    PrimaryFont,
    'd-block',
    'd-sm-none',
  ]);
  const jumbotronTitleMobileClasses = KDMakeClasses([
    textColorWhite,
    'mx-5',
    'pl-5',
    'mb-0',
  ]);
  const jumbotronSubTitleMobileClasses = KDMakeClasses([
    textColorWhite,
    'mx-5',
    'pl-5',
    FontSizeXSmall,
    PrimaryFont,
  ]);
  const jumbotronButtonMobileClasses = KDMakeClasses([
    'btn',
    'btn-light',
    'rounded-pill',
    'mx-5',
    'pl-5',
    FontSizeXSmall,
    PrimaryFont,
  ]);

  // SECOND SECTION
  const jumbotronSecondaryTextClasses = KDMakeClasses([
    'w-100',
    'position-absolute',
    'g-0',
    jumbotronSecondaryText,
    PrimaryFont,
    'd-none',
    'd-sm-block',
  ]);
  const jumbotronSecondaryTitleClasses = KDMakeClasses([
    textColorWhite,
    'mx-5',
    'pl-5',
    'text-center',
    'mt-1',
  ]);
  const jumbotronSecondarySubTitleClasses = KDMakeClasses([
    textColorWhite,
    'mx-5',
    'my-4',
    'pl-5',
    'text-center',
    PrimaryFont,
  ]);

  // SECOND SECTION MOBILE
  const jumbotronSecondaryTextMobileClasses = KDMakeClasses([
    'w-100',
    'position-absolute',
    'g-0',
    jumbotronSecondaryTextMobile,
    PrimaryFont,
    'd-block',
    'd-sm-none',
  ]);
  const jumbotronSecondaryTitleMobileClasses = KDMakeClasses([
    textColorWhite,
    'mx-5',
    'pl-5',
    'text-center',
    'mt-1',
    'mb-1',
  ]);
  const jumbotronSecondarySubTitleMobileClasses = KDMakeClasses([
    textColorWhite,
    'mx-5',
    'my-0',
    'pl-5',
    'text-center',
    FontSizeXSmall,
    PrimaryFont,
  ]);

  const rowClasses = KDMakeClasses([backgroundPrimary, 'g-0', 'pb-5']);
  return (
    <KDRow className={rowClasses}>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      <Col md={10} sm={10} lg={10} xl={10} xs={10}>
        <Row className="g-0 my-5 position-relative">
          <GatsbyImage
            image={jumboImage}
            alt=""
            objectFit="cover"
            className="my-0 w-100"
          />
          {/* DESKTOP SECTION */}
          <Row className={jumbotronTextClasses}>
            <Col md={6} sm={6} lg={6} xl={6} xs={6}>
              <h1 className={jumbotronTitleClasses}>
                Melayang Tinggi Melampaui Batas.
              </h1>
              <p className={jumbotronSubTitleClasses}>
                Teknologi Smart UAV untuk pemetaan dan peninjauan. Cepat,
                efisien, dan terjangkau.
              </p>
              <button type="button" className={jumbotronButtonClasses}>
                Dapatkan Sekarang!
              </button>
            </Col>
          </Row>
          {/* MOBILE SECTION */}
          <Row className={jumbotronTextMobileClasses}>
            <Col md={10} sm={10} lg={10} xl={10} xs={10}>
              <h6 className={jumbotronTitleMobileClasses}>
                Melayang Tinggi Melampaui Batas.
              </h6>
              <p className={jumbotronSubTitleMobileClasses}>
                Teknologi Smart UAV untuk pemetaan dan peninjauan. Cepat,
                efisien, dan terjangkau.
              </p>
              <button type="button" className={jumbotronButtonMobileClasses}>
                Dapatkan Sekarang!
              </button>
            </Col>
          </Row>
        </Row>
        <Row className="g-0 position-relative">
          <GatsbyImage
            image={jumboSubImage}
            alt=""
            objectFit="cover"
            className="my-0 w-100"
          />
          {/* DESKTOP SECTION */}
          <Row className={jumbotronSecondaryTextClasses}>
            <Col md={12} sm={12} lg={12} xl={12} xs={12}>
              <h1 className={jumbotronSecondaryTitleClasses}>
                Pemetaan Masa Depan UAV VTOL Terdepan di Industri
              </h1>
              <p className={jumbotronSecondarySubTitleClasses}>
                Dapatkan hasil pemetaan yang akurat dan terperinci dengan mudah
                menggunakan UAV kami yang canggih.
              </p>
            </Col>
          </Row>
          {/* MOBILE SECTION */}
          <Row className={jumbotronSecondaryTextMobileClasses}>
            <Col md={12} sm={12} lg={12} xl={12} xs={12}>
              <h6 className={jumbotronSecondaryTitleMobileClasses}>
                Pemetaan Masa Depan UAV VTOL Terdepan di Industri
              </h6>
              <p className={jumbotronSecondarySubTitleMobileClasses}>
                Dapatkan hasil pemetaan yang akurat dan terperinci dengan mudah
                menggunakan UAV kami yang canggih.
              </p>
            </Col>
          </Row>
        </Row>
      </Col>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
    </KDRow>
  );
}
