import { Col, Row } from 'react-bootstrap';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { backgroundPrimary, bgWhite } from '../styles/background.css';
import { paketInfoFrame, paketInfoImage } from '../styles/paketinfo.css';

import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import { PrimaryFont } from '../styles/font.css';
import React from 'react';
import { textColorGrey } from '../styles/text.css';

type InfoProps = {
  infoImage: IGatsbyImageData;
};
export default function KDPaketInfo({ infoImage }: InfoProps) {
  const rowClasses = KDMakeClasses([backgroundPrimary, 'g-0', 'pt-5', 'pb-5']);
  const innerRowClasses = KDMakeClasses([bgWhite, 'g-0', paketInfoFrame]);
  const paketParagraphClasses = KDMakeClasses([
    textColorGrey,
    PrimaryFont,
    'mb-0',
  ]);
  const paragraphRowClasses = KDMakeClasses(['m-4']);
  return (
    <KDRow className={rowClasses}>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      <Col md={10} sm={10} lg={10} xl={10} xs={10}>
        <Row className={innerRowClasses}>
          <Col md={6} sm={6} lg={6} xl={6} xs={12}>
            <Row className={paragraphRowClasses}>
              <p className={paketParagraphClasses}>
                <strong>Setiap pembelian unit termasuk:</strong>
              </p>
              <p className={paketParagraphClasses}>
                - Unit drone, ground control, dan manual book{' '}
              </p>
              <p className={paketParagraphClasses}>- Garansi servis 6 bulan</p>
              <p className={paketParagraphClasses}>
                - Peningkatan software selamanya
              </p>
            </Row>
          </Col>
          <Col md={6} sm={6} lg={6} xl={6} xs={6} className="d-none d-sm-block">
            <GatsbyImage
              image={infoImage}
              alt=""
              objectFit="cover"
              className={paketInfoImage}
            />
          </Col>
        </Row>
      </Col>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
    </KDRow>
  );
}
