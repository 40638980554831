import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import KDMakeClasses from '../utilities/common';
import React from 'react';
import { backgroundPrimary } from '../styles/background.css';
import { navBarShadow } from '../styles/nav.css';

interface NavProps {
  logoImage: IGatsbyImageData;
}

const KDNav: React.FC<NavProps> = ({ logoImage }) => {
  const navClassName = KDMakeClasses([
    'navbar-expand-sm',
    'd-none',
    'd-sm-block',
    backgroundPrimary,
    navBarShadow,
  ]);

  const logoHeadClassName = KDMakeClasses(['d-none', 'd-sm-block']);
  return (
    <Navbar expand="lg" className={navClassName}>
      <Container>
        <Row className="g-0 w-100">
          <Col md={4} sm={4} lg={4} xl={4} xs={4}>
            <Navbar.Brand href="/" className={logoHeadClassName}>
              <GatsbyImage
                image={logoImage}
                alt=""
                objectFit="cover"
                className="my-0"
              />
            </Navbar.Brand>
          </Col>
          <Col md={4} sm={4} lg={4} xl={4} xs={4}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="d-flex justify-content-center"
            >
              <Nav className="d-flex justify-content-center">
                <Nav.Link href="#spesifikasi" className="">
                  PRODUK
                </Nav.Link>
                <Nav.Link href="#order" className="">
                  INDUSTRI
                </Nav.Link>
                <Nav.Link href="#produk" className="">
                  TENTANG KAMI
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Col>
          <Col md={4} sm={4} lg={4} xl={4} xs={4}>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="me-2 d-flex flex-row-reverse"
            >
              <Nav className="text-center">
                <Nav.Link href="/hubungi" className="">
                  HUBUNGI
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default KDNav;
