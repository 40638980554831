import { Col, Row } from 'react-bootstrap';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import { PrimaryFont } from '../styles/font.css';
import React from 'react';
import { bgBlack } from '../styles/background.css';
import { ctaTitle } from '../styles/cta.css';
import { textColorWhite } from '../styles/text.css';

type CTAProps = {
  ctaImage: IGatsbyImageData;
};
export default function KDCTA({ ctaImage }: CTAProps) {
  const rowClasses = KDMakeClasses([bgBlack, 'g-0', 'pt-5', 'pb-5']);
  const ctaTitleClasses = KDMakeClasses([
    textColorWhite,
    PrimaryFont,
    ctaTitle,
  ]);
  const ctaParagraphClasses = KDMakeClasses([textColorWhite, PrimaryFont]);
  const ctaButtonClasses = KDMakeClasses([
    'btn',
    'btn-light',
    'rounded-pill',
    'my-5',
    PrimaryFont,
  ]);
  return (
    <KDRow className={rowClasses}>
      <Col md={6} sm={6} lg={6} xl={6} xs={12}>
        <GatsbyImage
          image={ctaImage}
          alt=""
          className=""
          imgStyle={{ objectFit: `contain` }}
        />
      </Col>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      <Col md={5} sm={5} lg={5} xl={5} xs={11}>
        <Row className="g-0">
          <Col md={10} sm={10} lg={10} xl={10} xs={10}>
            <h1 className={ctaTitleClasses}>Wujudkan mimpimu bersama kami!</h1>
            <p className={ctaParagraphClasses}>
              Kami siap menjawab semua pertanyaan Anda mengenai LUAV. Silahkan
              hubungi kami jika Anda ingin menjelajahi proyek Anda bersama atau
              jika Anda membutuhkan saran ahli. Hubungi kami.
            </p>
            <button type="button" className={ctaButtonClasses}>
              Hubungi Kami!
            </button>
          </Col>
        </Row>
      </Col>
    </KDRow>
  );
}
