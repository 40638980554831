import { Col, Row } from 'react-bootstrap';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { backgroundPrimary, bgWhite } from '../styles/background.css';

import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { PrimaryFont } from '../styles/font.css';
import React from 'react';
import { textColorGrey } from '../styles/text.css';
import { videoIframeWrapper } from '../styles/video.css';

export default function KDVideo() {
  const rowClasses = KDMakeClasses([backgroundPrimary, 'g-0', 'pt-5', 'pb-5']);
  const videoText = KDMakeClasses([
    PrimaryFont,
    'text-center',
    'py-2',
    textColorGrey,
  ]);
  const videoTextTitle = KDMakeClasses([PrimaryFont, 'text-center', 'py-2']);
  const videoWrapperClasses = KDMakeClasses(['yt-lite', videoIframeWrapper]);
  return (
    <KDRow className={rowClasses}>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      <Col md={10} sm={10} lg={10} xl={10} xs={10}>
        <Row className="g-0">
          <Col md={12} sm={12} lg={12} xl={12} xs={12}>
            <h2 className={videoTextTitle}>Sehari Bersama LUAV</h2>
            <p className={videoText}>
              Dirancang untuk semua orang. Video ini menampilkan betapa mudahnya
              mengoperasikan drone ini. Tidak perlu alat khusus, cukup perakitan
              sederhana di lapangan, lalu langsung rencanakan dan pantau misi
              Anda.
            </p>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
          <Col md={10} sm={10} lg={10} xl={10} xs={10}>
            <div className="rounded-circle">
              <LiteYouTubeEmbed
                id="bIlE9vVIFZU"
                title="A day with LUAV"
                wrapperClass={videoWrapperClasses}
                poster="hqdefault"
                noCookie={true}
              />
            </div>
          </Col>
          <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
        </Row>
      </Col>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
    </KDRow>
  );
}
