import { Col, Row } from 'react-bootstrap';
import { FontBold, PrimaryFont } from '../styles/font.css';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { backgroundPrimary, bgWhite } from '../styles/background.css';
import { featureBox, featureIcon } from '../styles/feature.css';

import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import React from 'react';

type LastInfoStruct = {
  title: string;
  icon: IGatsbyImageData;
};
type LastInfoProps = {
  lastInfoData: LastInfoStruct[];
};

export default function KDLastInfo({ lastInfoData }: LastInfoProps) {
  const rowClasses = KDMakeClasses([backgroundPrimary, 'g-0', 'pt-5', 'pb-5']);
  const featureBoxClasses = KDMakeClasses([
    bgWhite,
    'g-0',
    'rounded',
    featureBox,
  ]);
  const lastInfoIconClasses = KDMakeClasses(['mt-2', 'text-center']);
  const lastInfoTitleClasses = KDMakeClasses([
    PrimaryFont,
    FontBold,
    'text-center',
    'm-4',
  ]);
  const lastInfoTitle = KDMakeClasses([PrimaryFont, 'text-center']);
  const lastInfoItemContent = KDMakeClasses([
    PrimaryFont,
    'mx-5',
    'my-2',
    'pb-5',
    'text-center',
  ]);
  return (
    <KDRow className={rowClasses}>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      <Col md={10} sm={10} lg={10} xl={10} xs={10}>
        <Row className="g-0">
          <Col md={12} sm={12} lg={12} xl={12} xs={12}>
            <h2 className={lastInfoTitle}>
              Pemetaan dengan Tenang: Investasi Aman, Pilot Terlatih
            </h2>
            <p className={lastInfoItemContent}>
              Investasi Anda aman bersama LUAV. Garansi penuh hingga 2 tahun,
              respon cepat, dan suku cadang selalu tersedia memastikan platform
              Anda selalu siap pakai. Bekerja dengan tenang dan maksimalkan
              produktivitas Anda.
            </p>
          </Col>
        </Row>
        <Row className="g-0">
          {lastInfoData.map((info, index) => (
            <Col
              md={4}
              sm={4}
              lg={4}
              xl={4}
              xs={12}
              className="p-3"
              key={index}
            >
              <Row>
                <Col
                  md={12}
                  sm={12}
                  lg={12}
                  xl={12}
                  xs={12}
                  className="mx-auto d-flex  
                  align-items-center  
                  justify-content-center"
                >
                  <GatsbyImage
                    image={info.icon}
                    alt=""
                    className={lastInfoIconClasses}
                    imgStyle={{ objectFit: `contain` }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} lg={12} xl={12} xs={12}>
                  <h3 className={lastInfoTitleClasses}>{info.title}</h3>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Col>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
    </KDRow>
  );
}
