import React from 'react';
import { useSiteMetadata } from '../hooks/sitemetadata';

type MetaItem = {
  name: string;
  content: string;
};

type SEOProps = {
  title?: string;
  description?: string;
  url?: string;
  author?: string;
  keywords?: string[];
  meta?: MetaItem[];
  image?: string;
};

const KDHead: React.FC<SEOProps> = (props) => {
  const {
    title,
    description,
    url,
    author,
    meta = [],
    keywords = [],
    image,
  } = useSiteMetadata();
  const siteTitle = props.title || title;
  const siteDescription = props.description || description;
  const siteUrl = props.url || url;
  const siteAuthor = props.author || author;
  const siteImage = props.image || image;
  const siteKeywords = [...keywords, props.keywords].join(',');

  return (
    <>
      <title>{siteTitle}</title>
      <meta name="canonical" content={siteUrl} />
      <meta name="description" content={siteDescription} />
      <meta name="image" content={siteImage} />
      <meta name="og:url" content={siteUrl} />
      <meta name="og:type" content="article" />
      <meta name="og:title" content={siteTitle} />
      <meta name="og:description" content={siteDescription} />
      <meta name="og:image" content={siteImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={siteAuthor} />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={siteDescription} />
      <meta name="twitter:image" content={siteImage} />
      <meta name="keywords" content={siteKeywords} />
    </>
  );
};

export { KDHead };
