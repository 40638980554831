import { Col, Row } from 'react-bootstrap';
import { FontBold, PrimaryFont } from '../styles/font.css';

import KDMakeClasses from '../utilities/common';
import React from 'react';
import { spesifikasiUnderline } from '../styles/spesifikasi.css';
import { textColorGrey } from '../styles/text.css';

export default function SpecBlackbirdFW() {
  const specRowTitle = KDMakeClasses([spesifikasiUnderline, 'mx-2', 'mt-5']);
  const specRowContent = KDMakeClasses([
    spesifikasiUnderline,
    'mx-2',
    'mt-3',
    textColorGrey,
  ]);

  const productInfos = [
    {
      name: 'Model',
      value: 'Delta Wing',
    },
    {
      name: 'Wing span',
      value: '2120 mm',
    },
    {
      name: 'Fuselage length',
      value: '790 mm',
    },
    {
      name: 'Wing area',
      value: '80 dm^2',
    },
    {
      name: 'Flying weight',
      value: '2500 - 3000 g',
    },
    {
      name: 'C.G',
      value: 'From head to back 430 - 440 mm',
    },
    {
      name: 'Take off',
      value: 'Hand cast, Catapult shot',
    },
    {
      name: 'Landing',
      value: 'Glide down, Parachute landing',
    },
    {
      name: 'Max. anti-wing capability',
      value: 'Level 4',
    },
    {
      name: 'Air speed',
      value: '65 - 70 km/h',
    },
    {
      name: 'Max. of flying time',
      value: '25 min',
    },
    {
      name: 'Max. of flight level',
      value: '200 m',
    },
  ];

  const productStandards = [
    {
      name: 'Motor',
      value: 'Sunnysky4250KV500 / OS5010KV810',
    },
    {
      name: 'Propeller',
      value: '12080F / 12060E',
    },
    {
      name: 'Servo',
      value: '17 g x 2 pcs',
    },
    {
      name: 'ESC',
      value: '60A/100A',
    },
    {
      name: 'Battery',
      value: '22.2v 5000 mAh - 6500 mAh',
    },
    {
      name: 'Radio',
      value: '4CH 4SERVO',
    },
    {
      name: 'Companion Computer',
      value: 'Raspberry Pi',
    },
    {
      name: 'Main Operating System',
      value: 'Ubuntu with LUAV v1.0.324 software',
    },
  ];
  return (
    <Row className="g-0">
      <Col md={12} sm={12} lg={12} xl={12} xs={12}>
        <Row className={specRowTitle}>
          <Col md={12} sm={12} lg={12} xl={12} xs={12}>
            <p className={FontBold}>Informasi Produk:</p>
          </Col>
        </Row>
        {productInfos.map((product, index) => (
          <Row className={specRowContent} key={index}>
            <Col md={6} sm={6} lg={6} xl={6} xs={6}>
              <p className={PrimaryFont}>{product.name}</p>
            </Col>
            <Col md={6} sm={6} lg={6} xl={6} xs={6}>
              <p className={PrimaryFont}>{product.value}</p>
            </Col>
          </Row>
        ))}
      </Col>
      <Col md={12} sm={12} lg={12} xl={12} xs={12}>
        <Row className={specRowTitle}>
          <Col md={12} sm={12} lg={12} xl={12} xs={12}>
            <p className={FontBold}>Standard:</p>
          </Col>
        </Row>
        {productStandards.map((product, index) => (
          <Row className={specRowContent} key={index}>
            <Col md={6} sm={6} lg={6} xl={6} xs={6}>
              <p className={PrimaryFont}>{product.name}</p>
            </Col>
            <Col md={6} sm={6} lg={6} xl={6} xs={6}>
              <p className={PrimaryFont}>{product.value}</p>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  );
}
