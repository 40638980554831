import { Col, Row } from 'react-bootstrap';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { backgroundPrimary, bgWhite } from '../styles/background.css';
import { featureBox, featureIcon } from '../styles/feature.css';

import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import { PrimaryFont } from '../styles/font.css';
import React from 'react';

type FeatureStruct = {
  title: string;
  content: string;
  icon: IGatsbyImageData;
};
type FeatureProps = {
  featureData: FeatureStruct[];
};

export default function KDFeature({ featureData }: FeatureProps) {
  const rowClasses = KDMakeClasses([backgroundPrimary, 'g-0', 'pt-5', 'pb-5']);
  const featureBoxClasses = KDMakeClasses([
    bgWhite,
    'g-0',
    'rounded',
    featureBox,
  ]);
  const featureIconClasses = KDMakeClasses([
    'mx-5',
    'mt-5',
    'mb-3',
    featureIcon,
  ]);
  const featureTitle = KDMakeClasses([PrimaryFont, 'text-center']);
  const featureItemTitle = KDMakeClasses([PrimaryFont, 'mx-5', 'my-2']);
  const featureItemContent = KDMakeClasses([
    PrimaryFont,
    'mx-5',
    'my-2',
    'pb-5',
  ]);
  return (
    <KDRow className={rowClasses}>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      <Col md={10} sm={10} lg={10} xl={10} xs={10}>
        <Row className="g-0">
          <Col md={12} sm={12} lg={12} xl={12} xs={12}>
            <h2 className={featureTitle}>Fitur Unggulan</h2>
          </Col>
        </Row>
        <Row className="g-0">
          {featureData.map((feature, index) => (
            <Col
              md={3}
              sm={3}
              lg={3}
              xl={3}
              xs={12}
              className="p-3"
              key={index}
            >
              <Row className={featureBoxClasses}>
                <Col md={12} sm={12} lg={12} xl={12} xs={12}>
                  <GatsbyImage
                    image={feature.icon}
                    alt=""
                    className={featureIconClasses}
                    imgStyle={{ objectFit: `contain` }}
                  />
                </Col>
                <Col md={12} sm={12} lg={12} xl={12} xs={12}>
                  <h3 className={featureItemTitle}>{feature.title}</h3>
                </Col>
                <Col md={12} sm={12} lg={12} xl={12} xs={12}>
                  <p className={featureItemContent}>{feature.content}</p>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Col>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
    </KDRow>
  );
}
