import { Col, Row } from 'react-bootstrap';
import { FontLight, PrimaryFont } from '../styles/font.css';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import { Link } from 'gatsby';
import React from 'react';
import { bgWhite } from '../styles/background.css';
import { textColorGrey } from '../styles/text.css';

type FooterProps = {
  footerImage: IGatsbyImageData;
};
export default function KDFooter({ footerImage }: FooterProps) {
  const rowClasses = KDMakeClasses([bgWhite, 'g-0', 'pt-5']);
  const contentRowClasses = KDMakeClasses(['g-0']);
  const copyText = KDMakeClasses(['text-center', textColorGrey, FontLight]);
  const footerLinkClasses = KDMakeClasses([PrimaryFont, textColorGrey]);
  return (
    <KDRow className={rowClasses}>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      <Col md={10} sm={10} lg={10} xl={10} xs={10}>
        <Row className={contentRowClasses}>
          <Col md={6} sm={6} lg={6} xl={6} xs={12}>
            <GatsbyImage
              image={footerImage}
              alt=""
              className="mb-5"
              imgStyle={{ objectFit: `contain` }}
            />
          </Col>
          <Col md={3} sm={3} lg={3} xl={3} xs={12}></Col>
          <Col md={3} sm={3} lg={3} xl={3} xs={12}>
            <Link to="#spesifikasi" className={footerLinkClasses}>
              <p>Produk</p>
            </Link>
            <Link to="#spesifikasi" className={footerLinkClasses}>
              <p>Industri</p>
            </Link>
            <Link to="#spesifikasi" className={footerLinkClasses}>
              <p>Tentang Kami</p>
            </Link>
          </Col>
        </Row>
        <Row className="g-0">
          <Col md={12} sm={12} lg={12} xl={12} xs={12}>
            <hr></hr>
            <p className={copyText}>
              Copyright © LUAV Aerial Technology 2024 | Website by{' '}
              <a
                href="https://keshwaridesign.com"
                style={{ textDecoration: 'none' }}
                className={textColorGrey}
              >
                Keshwari Design Studio
              </a>
            </p>
          </Col>
        </Row>
      </Col>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
    </KDRow>
  );
}
